import { ref } from "vue";
import { useLoginPropertiesStore } from "authentication/stores/login-properties.store";
import { loginWithGoogle } from "authentication/js/api/login.api";
import showFlashMessage from "core/js/flashmessage/flash-message";
import CredentialResponse = google.accounts.id.CredentialResponse;

const GOOGLE_IDENTITY_CLIENT_LIBRARY_SRC = "https://accounts.google.com/gsi/client";

export function useGoogleAuthScript() {
  const store = useLoginPropertiesStore();
  const loginButtonWrapperId = "googleLoginButtonWrapper";
  const googleAuthLibraryLoaded = ref(false);
  const loggingInWithGoogle = ref(false);

  async function handleGoogleAuthResponse(response: CredentialResponse) {
    loggingInWithGoogle.value = true;

    try {
      const loginResult = await loginWithGoogle(response.credential);

      if (loginResult.redirectUrl) {
        window.location.assign(loginResult.redirectUrl);
      } else {
        loggingInWithGoogle.value = false;
        showFlashMessage("An unexpected error occurred, please try again.", { displayType: "danger" });
      }
    } catch (error) {
      loggingInWithGoogle.value = false;
      throw error;
    }
  }

  function handleScriptError(error: string | Event) {
    showFlashMessage(
      "There was an error in loading the Google SSO button. "
      + "Please contact your local support desk if you are unable to sign in after multiple attempts.",
      { displayType: "danger" },
    );

    throw new Error(typeof error === "string" ? error : error.type);
  }

  function onScriptLoaded() {
    window.google.accounts.id.initialize({
      client_id: store.loginProperties.googleAuthClientId as string,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      callback: handleGoogleAuthResponse,
    });

    // Sign out of any existing Google sessions
    window.google.accounts.id.disableAutoSelect();

    googleAuthLibraryLoaded.value = true;
  }

  function loadGoogleAuthScript() {
    if (window.google) {
      onScriptLoaded();
    } else {
      const script = document.createElement("script");
      script.src = GOOGLE_IDENTITY_CLIENT_LIBRARY_SRC;
      script.onload = onScriptLoaded;
      script.onerror = handleScriptError;
      script.async = true;

      document.head.appendChild(script);
    }
  }

  function renderGoogleLoginButton() {
    // Display the "Sign in with Google" button
    window.google.accounts.id.renderButton(
      document.getElementById(loginButtonWrapperId) as HTMLElement,
      { type: "standard", theme: "outline", size: "large" },
    );

    // Display the One Tap dialog
    window.google.accounts.id.prompt();
  }

  return {
    loginButtonWrapperId,
    googleAuthLibraryLoaded,
    loggingInWithGoogle,

    loadGoogleAuthScript,
    renderGoogleLoginButton,
  };
}