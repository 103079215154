import {
  UserAuthenticationSettings,
  UserAuthenticationSettingsValidationErrors,
} from "authentication/js/api/user-authentication-settings.api";

export function validateUserAuthenticationSettings(
  userAuthenticationSettings: UserAuthenticationSettings,
  isMfaConfigOptionEnabled: boolean,
): UserAuthenticationSettingsValidationErrors {
  const validationErrors = {
    timeoutSettings: { duration: "" },
    mfaSettings: { durationDays: "" },
  } as UserAuthenticationSettingsValidationErrors;
  if (!Number.isSafeInteger(+userAuthenticationSettings.timeoutSettings.duration)) {
    validationErrors.timeoutSettings.duration = "Session duration must be a valid whole number";
  }
  if (isMfaConfigOptionEnabled) {
    if (userAuthenticationSettings.mfaSettings?.durationDays === undefined
        || !Number.isSafeInteger(+userAuthenticationSettings.mfaSettings.durationDays)) {
      validationErrors.mfaSettings.durationDays = "MFA duration must be a valid whole number of days";
    }
  }
  return validationErrors;
}