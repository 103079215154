<template>
  <div>
    <atom-info-box role="alert">
      <p>Additional verification is required to complete your sign-in.</p>

      <p>An email has been sent to <strong>{{ username }}</strong>.</p>

      <p>Please open the email for further instructions.</p>
    </atom-info-box>

    <!-- TODO Resend email link -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";

export default defineComponent({
  components: { AtomInfoBox },
  props: {
    username: { type: String, required: true },
  },
});
</script>
