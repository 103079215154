<template>
  <login-layout>
    <template #body>
      <div>
        <h1 id="login-page__not-found-title">
          Sorry, that page could not be found.
        </h1>
        <p>If you typed the web address, check it is correct.</p>
        <p>If you pasted the web address, check you copied the entire address.</p>
        <p>
          If the web address is correct or you selected a link or button, please
          <a href="mailto:support@ecase.co.uk?subject=Page not found error">contact eCase support</a>.
        </p>
      </div>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";

export default {
  components: { LoginLayout },
};
</script>

<style lang="scss">

#login-page {

  &__not-found-title {
    font-size: 1.17em;
    font-weight: 700;
  }
}

</style>