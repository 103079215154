import "core/core.app.js";
import { pinia } from "core/js/register-pinia.ts";
import { createApp } from "vue";
import { attachErrorHandlers } from "core/js/error-logging.ts";

import router from "authentication/authentication.router.js";
import UserAuthenticationSettingsPage from "authentication/pages/UserAuthenticationSettingsPage.vue";
import Whitelist from "./components/ipwhitelist/IpWhitelist.vue";
import SamlSettings from "./components/sso/SamlSettings.vue";
import LoginPage from "./components/login/page/LoginPage.vue";

// Import stylesheets
import "./scss/authentication.scss";

if (document.getElementById("authentication-app")) {
  const authenticationVueComponent = createApp({
    components: {
      whitelist: Whitelist,
      "saml-settings": SamlSettings,
      "login-page": LoginPage,
      "user-authentication-settings": UserAuthenticationSettingsPage,
    },
  });

  authenticationVueComponent.use(pinia);
  authenticationVueComponent.use(router);
  authenticationVueComponent.mount("#authentication-app");

  attachErrorHandlers(authenticationVueComponent);
}
