<template>
  <div class="skeleton-form">
    <div class="row">
      <label class="prompt north skeleton-form__label skeleton-form__label--short skeleton-loading"/>
      <input class="skeleton-form__input skeleton-loading" disabled type="text">
    </div>
    <div class="row">
      <label class="prompt north skeleton-form__label skeleton-form__label--long skeleton-loading"/>
      <input class="skeleton-form__input skeleton-loading" disabled type="text">
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
@use "core/scss/helpers/skeleton-loading" as loader;
@include loader.skeleton-loading;

.skeleton-form {
  background: white;

  &__label {
    height: 1vh;
    margin-bottom: 0.5em;

    &--short {
      width: 5em;
    }

    &--long {
      width: 12em;
    }
  }

  &__input {
    margin-bottom: 2em;
  }
}
</style>