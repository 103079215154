<template>
  <div class="reCAPTCHA-container">
    <div v-if="reCaptchaLibraryLoaded" :id="reCaptchaWrapperId" class="g-recaptcha" :data-sitekey="reCaptchaSiteKey"
         :data-callback="globalReCaptchaResponseCallbackFunction"
         :data-expired-callback="globalReCaptchaExpiredCallbackFunction"/>
  </div>
</template>

<script>
import { useGoogleReCaptchaScript } from "authentication/js/composable/google-recaptcha-script";

export default {
  emits: ["onReCaptchaAttempt", "onReCaptchaExpired"],
  setup(_, { emit }) {
    function handleReCaptchaResponse(response) {
      emit("onReCaptchaAttempt", response);
    }

    function handleReCaptchaExpired() {
      emit("onReCaptchaExpired");
    }

    const {
      reCaptchaSiteKey,
      reCaptchaWrapperId,
      globalReCaptchaResponseCallbackFunction,
      globalReCaptchaExpiredCallbackFunction,
      reCaptchaLibraryLoaded,

      loadGoogleReCaptchaScript,
    } = useGoogleReCaptchaScript(handleReCaptchaResponse, handleReCaptchaExpired);

    return {
      reCaptchaSiteKey,
      reCaptchaWrapperId,
      globalReCaptchaResponseCallbackFunction,
      globalReCaptchaExpiredCallbackFunction,
      reCaptchaLibraryLoaded,

      loadGoogleReCaptchaScript,
    };
  },
  async mounted() {
    await this.loadGoogleReCaptchaScript();
  },
};
</script>

<style lang="scss">

.reCAPTCHA-container {
  margin-bottom: 1em;
}

@media screen and (max-width: 48.0625rem) {
  .g-recaptcha {
    -moz-transform: scale(0.77);
    -ms-transform: scale(0.77);
    -o-transform: scale(0.77);
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    -o-transform-origin: 0;
    -webkit-transform: scale(0.77);
    transform: scale(0.77);
    -webkit-transform-origin: 0 0;
    transform-origin: 0;
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.77,M12=0,M21=0,M22=0.77,SizingMethod='auto expand');
    font-size: 1.1vw;
  }
}

@media screen and (min-width: 48.0625rem) {
  .g-recaptcha {
    transform: none;
    filter: none;

    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
  }
}

</style>
