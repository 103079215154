<template>
  <svg :class="classes" x="0px" y="0px" viewBox="0 0 228.1 70">
    <title>eCase</title>
    <g>
      <g>
        <path fill="currentColor" d="M49.7,37.3c-5.1,0-8.3,3.2-8.9,8.2h17.7C58.4,40.5,55.1,37.3,49.7,37.3z"/>
        <path fill="currentColor" d="M53.9,0H16.1C7.2,0,0,7.2,0,16.1v37.8C0,62.8,7.2,70,16.1,70h37.8C62.8,70,70,62.8,70,53.9V16.1
                    C70,7.2,62.8,0,53.9,0z M67.1,52H40.8c0.6,5.1,4.5,8.2,9.3,8.2c4.3,0,7.1-2,9.3-4.7l6.2,4.7c-4,5-9.1,6.9-14.6,6.9
                    c-10.4,0-18.8-7.3-18.8-18.1c0-10.9,8.3-18.1,18.8-18.1c9.6,0,16.2,6.8,16.2,18.8V52z"/>
      </g>
      <path fill="currentColor" d="M110.9,27.3c-3.7-4-7.2-5-10.7-5c-10.5,0-17.5,8.1-17.5,18.2c0,10.9,7,18.9,17.5,18.9
                  c4.1,0,8.1-1.9,11.4-6.2l7.5,5.3c-4.6,6.3-11.5,9.1-19,9.1c-15.7,0-26.9-10.6-26.9-26.5c0-16.3,11.2-27,26.9-27
                  c6.9,0,12.8,2.2,17.8,8.1L110.9,27.3z"/>
      <path fill="currentColor" d="M146,61.5h-0.2c-2.4,3.9-6.5,5.6-11.1,5.6c-6.4,0-12.6-3.5-12.6-10.4c0-11.2,13.1-12,
                  21.7-12h2.2v-0.9c0-4.2-3.3-6.5-7.9-6.5c-3.6,0-6.9,1.4-9.2,3.6l-4.5-4.5c3.8-3.9,9.2-5.5,14.7-5.5c14.7,0,
                  14.7,10.6,14.7,15.5v19.8H146V61.5zM145.5,50.8h-1.8c-4.7,0-12.9,0.4-12.9,5.3c0,3.2,3.2,4.5,6,4.5c5.9,0,
                  8.7-3.1,8.7-7.9V50.8z"/>
      <path fill="currentColor" d="M181.4,41.3c-1.7-2.3-3.8-3.5-6.8-3.5c-2.3,0-5,1.1-5,3.7c0,6.2,18.6,1.2,18.6,14.6
                  c0,8.2-7.8,11.1-14.9,11.1c-5.3,0-9.9-1.4-13.4-5.3l5.8-5.4c2.2,2.4,4.5,4.2,8.2,4.2c2.5,0,5.8-1.2,
                  5.8-4c0-7.1-18.6-1.5-18.6-14.7c0-7.7,6.9-11.1,13.9-11.1c4.6,0,9.5,1.4,12.3,5.2L181.4,41.3z"/>
      <path fill="currentColor" d="M201.8,52c0.6,5.1,4.5,8.2,9.3,8.2c4.3,0,7.1-2,9.3-4.7l6.2,4.7c-4,5-9.1,6.9-14.6,6.9
                  c-10.4,0-18.8-7.3-18.8-18.1c0-10.9,8.3-18.1,18.8-18.1c9.6,0,16.2,6.8,16.2,18.8V52H201.8z M219.5,
                  45.5c-0.1-5-3.4-8.2-8.8-8.2c-5.1,0-8.3,3.2-8.9,8.2H219.5z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "large" },
  },
  computed: {
    classes() {
      return [
        "ecase-svg-logo",
        `ecase-svg-logo--${this.size}`,
      ];
    },
  },
};
</script>

<style lang="scss">

svg.ecase-svg-logo {
  &--large {
    width: 12.25rem !important;
    height: 5.625rem !important;
  }
}

</style>
