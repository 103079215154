export const TimeUnits = ["MINUTES", "HOURS"] as const;

export type TimeUnit = typeof TimeUnits[number];

export function getTimeUnitOptions() {
  return TimeUnits.map(timeUnit => ({
    id: timeUnit,
    displayText: getTimeUnitDisplayText(timeUnit),
  }));
}

function getTimeUnitDisplayText(timeUnit: TimeUnit) {
  switch (timeUnit) {
    case "MINUTES":
      return "Minutes";
    case "HOURS":
      return "Hours";
  }
}
