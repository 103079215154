import { AjaxError, ajaxGet, ajaxPut } from "core/js/spring-ajax";

const SAML_SETTINGS_API_URL = "/authentication/rest/api/settings/sso/saml";

export interface SamlSettings {
  enabled: boolean,
  idpUrl: string,
  idpEntityId: string,
  primaryX509Certificate: string,
  secondaryX509Certificate: string,
  spEntityId: string,
}

export interface SamlSettingsSaveResult {
  valid: boolean,
  validationErrors: object,
}

export async function getSamlSettings(): Promise<SamlSettings> {
  return ajaxGet({ url: SAML_SETTINGS_API_URL });
}

export async function saveSamlSettingsToUrl(
  samlSettings: SamlSettings,
  saveUrl: string,
): Promise<SamlSettingsSaveResult> {
  try {
    await ajaxPut({ url: saveUrl, data: samlSettings });
    return { valid: true, validationErrors: {} };
  } catch (error) {
    const ajaxError = error as AjaxError;
    if (ajaxError.status === 422) {
      return { valid: false, validationErrors: { ...ajaxError.responseJSON } };
    } else {
      throw error;
    }
  }
}

export async function saveSamlSettings(
  samlSettings: SamlSettings,
): Promise<SamlSettingsSaveResult> {
  return saveSamlSettingsToUrl(samlSettings, SAML_SETTINGS_API_URL);
}