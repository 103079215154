<template>
  <login-layout :loading="!tokenChecked">
    <template #body>
      <atom-info-box v-if="tokenMissing" priority="danger">
        This token link is invalid. Make sure it is the same as the one provided in the email you received.
      </atom-info-box>

      <atom-info-box v-else-if="!tokenValid" priority="danger">
        The reset token used was invalid. This means that the token may have expired or was already used.
      </atom-info-box>

      <template v-else>
        <p>To reset your password, please enter a new one below. If you no longer want to reset your password you can cancel.</p>

        <atom-info-box>
          <p>Passwords must have:</p>

          <ul class="ecase-sign-in__form-password-policy-list">
            <li :class="getDisplayClasses(isMinimumEightCharacters)">
              a minimum length of 8 characters
            </li>
            <li :class="getDisplayClasses(hasAtLeastOneLowerCaseCharacter)">
              at least one lower-case letter
            </li>
            <li :class="getDisplayClasses(hasAtLeastOneUpperCaseCharacter)">
              at least one upper-case letter
            </li>
            <li :class="getDisplayClasses(hasAtLeastOneNumber)">
              at least one number
            </li>
            <li :class="getDisplayClasses(hasAtLeastOneSpecialCharacter)">
              at least one symbol (!@#$%^&amp;*()\-_=+{};:,&lt;.>)
            </li>
          </ul>
        </atom-info-box>

        <atom-info-box v-if="validationError.message" priority="danger" role="alert">
          {{ validationError.message }}
        </atom-info-box>

        <atom-info-box v-else-if="resetPasswordFailure" priority="danger" role="alert">
          Unfortunately, there was an internal error processing this request. Please speak to your local support desk.
        </atom-info-box>

        <atom-text-input id="password" v-model="password" type="password" label="New password"
                         :error-message="validationError.password"/>

        <atom-text-input id="password-confirm" v-model="passwordConfirmation" type="password" label="Confirm new password"
                         :error-message="validationError.passwordConfirmation"/>

        <div class="ecase-sign-in__form-action-group">
          <atom-button label="Set password" type="primary" :disabled="loading || undefined" @click="submitResetPassword"/>
          <atom-button label="Cancel" type="link-button" :disabled="loading || undefined" @click="navigateToLogin"/>
        </div>
      </template>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import AtomTextInput from "core/components/atom/textInput/AtomTextInput.vue";
import AtomButton from "core/components/atom/button/AtomButton.vue";
import {
  canResetPassword, PASSWORD_CHANGED_PARAM,
  resetPassword,
} from "authentication/js/api/password.api";
import { useNewPassword } from "authentication/js/composable/new-password";
import announce from "core/js/announce";

export default {
  components: {
    AtomButton,
    LoginLayout,
    AtomTextInput,
    AtomInfoBox,
  },
  setup() {
    const {
      password,
      passwordConfirmation,

      isMinimumEightCharacters,
      hasAtLeastOneLowerCaseCharacter,
      hasAtLeastOneUpperCaseCharacter,
      hasAtLeastOneNumber,
      hasAtLeastOneSpecialCharacter,
      passwordsMatch,

      getDisplayClasses,
    } = useNewPassword();

    return {
      password,
      passwordConfirmation,

      isMinimumEightCharacters,
      hasAtLeastOneLowerCaseCharacter,
      hasAtLeastOneUpperCaseCharacter,
      hasAtLeastOneNumber,
      hasAtLeastOneSpecialCharacter,
      passwordsMatch,

      getDisplayClasses,
    };
  },
  data() {
    return {
      loading: false,
      tokenChecked: false,
      tokenValid: false,
      tokenMissing: false,
      resetPasswordStatus: undefined,
      validationError: {},
    };
  },
  computed: {
    resetPasswordSuccess() {
      return this.resetPasswordStatus === "SUCCESS";
    },
    resetPasswordFailure() {
      return this.resetPasswordStatus === "FAILURE";
    },
    validationErrorsExist() {
      return this.validationError && Object.keys(this.validationError).length;
    },
  },
  async created() {
    if (!this.$route.query.token) {
      this.tokenMissing = true;
    } else {
      this.tokenValid = await canResetPassword(this.$route.query.token);
    }

    this.tokenChecked = true;
  },
  methods: {
    navigateToLogin() {
      this.$router.push({ name: "login-page" });
    },
    async submitResetPassword() {
      this.validationError = {};
      this.loading = true;

      if (!this.password) {
        this.validationError.password = "Enter a new password";
      }

      if (!this.passwordConfirmation) {
        this.validationError.passwordConfirmation = "Enter your new password again";
      } else if (!this.passwordsMatch) {
        this.validationError.passwordConfirmation = "The password confirmation does not match";
      }

      try {
        if (!this.validationErrorsExist) {
          const resetPasswordResult = await resetPassword(this.password, this.$route.query.token);
          this.resetPasswordStatus = resetPasswordResult.resetPasswordStatus;

          if (this.resetPasswordSuccess) {
            await this.$router.push({ name: "login-page", query: { [PASSWORD_CHANGED_PARAM]: "success" } });
          } else if (this.resetPasswordFailure) {
            this.validationError = { message: resetPasswordResult.message };
          }
        } else {
          announce(`There were some errors with your submission. ${this.validationError.password || ""}, `
              + `${this.validationError.passwordConfirmation || ""}`);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
