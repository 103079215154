import { ajaxGet, ajaxPost } from "core/js/spring-ajax";

const RESET_PASSWORD_ALLOWED_URL = "/authentication/rest/password/reset-allowed";
const REQUEST_PASSWORD_RESET_URL = "/authentication/rest/password/request-password-reset";
const RESET_PASSWORD_URL = "/authentication/rest/password/reset-password";

export const PASSWORD_CHANGED_PARAM = "passwordChanged";

interface JsonResetPasswordAllowed {
  resetAllowed: boolean
}

export type ResetPasswordStatus = "SUCCESS" | "FAILURE";

export interface ResetPasswordResult {
  resetPasswordStatus: ResetPasswordStatus;
  message?: string
}

export async function canResetPassword(token: string) {
  const jsonTokenExists = await ajaxGet<JsonResetPasswordAllowed>({ url: `${RESET_PASSWORD_ALLOWED_URL}?token=${token}` });
  return jsonTokenExists.resetAllowed;
}

export async function requestPasswordReset(username: string, reCaptchaToken: string | undefined = undefined)
  : Promise<ResetPasswordResult> {
  return ajaxPost({
    url: REQUEST_PASSWORD_RESET_URL,
    data: { username, reCaptchaToken },
  });
}

export async function resetPassword(password: string, token: string): Promise<ResetPasswordResult> {
  return ajaxPost({
    url: RESET_PASSWORD_URL,
    data: { password, token },
  });
}
