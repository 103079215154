<template>
  <div class="skeleton-paragraph">
    <p class="skeleton-paragraph__text skeleton-paragraph__text--long skeleton-loading"/>
    <p class="skeleton-paragraph__text skeleton-paragraph__text--short skeleton-loading"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>

<style scoped lang="scss">
@use "core/scss/helpers/skeleton-loading" as loader;
@include loader.skeleton-loading;

.skeleton-paragraph {
  background: white;

  &__text {
    height: 2vh;
    margin-bottom: 1rem;

    &--short {
      width: 5em;
    }

    &--long {
      width: 8em;
    }
  }
}
</style>