import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";
import { debounceAction } from "core/store/store-utils";
import { getLoginProperties, LoginProperties } from "authentication/js/api/login.api";

export const useLoginPropertiesStore = defineStore("feature/authentication/login-properties", () => {
  const loginProperties = ref({}) as Ref<LoginProperties>;

  const multipleLoginOptionsExist = computed(() => loginProperties.value.samlEnabled || loginProperties.value.googleAuthClientId);

  async function fetchLoginProperties() {
    if (loginProperties.value && Object.keys(loginProperties.value).length) {
      return;
    }

    loginProperties.value = await getLoginProperties();
  }

  return {
    loginProperties,
    multipleLoginOptionsExist,
    fetchLoginProperties: debounceAction(fetchLoginProperties),
  };
});
