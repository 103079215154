<template>
  <div :id="loginButtonWrapperId"/>
</template>

<script>
import { useGoogleAuthScript } from "authentication/js/composable/google-auth-script";

export default {
  setup() {
    const { loginButtonWrapperId, renderGoogleLoginButton } = useGoogleAuthScript();
    return { loginButtonWrapperId, renderGoogleLoginButton };
  },
  mounted() {
    this.renderGoogleLoginButton();
  },
};
</script>
