import { createRouter, createWebHistory } from "vue-router";
import springUrl from "core/js/spring-url.js";
import { actionStart } from "core/js/load-tracker.js";
import LoginPage from "authentication/components/login/page/LoginPage.vue";
import VerifyPage from "authentication/components/login/page/VerifyPage.vue";
import ForgotPasswordPage from "authentication/components/login/page/ForgotPasswordPage.vue";
import NotFoundPage from "authentication/components/login/page/NotFoundPage.vue";
import ResetPasswordPage from "authentication/components/login/page/ResetPasswordPage.vue";

export const routes = [
  {
    path: springUrl("authentication/login"),
    name: "login-page",
    component: LoginPage,
    meta: { documentTitle: "Sign in" },
  },
  {
    path: springUrl("authentication/login/verify"),
    name: "verify-page",
    component: VerifyPage,
    meta: { documentTitle: "Verify your identity" },
  },
  {
    path: springUrl("authentication/login/forgot-password"),
    name: "forgot-password-page",
    component: ForgotPasswordPage,
    meta: { documentTitle: "Request password reset" },
  },
  {
    path: springUrl("authentication/login/reset-password"),
    name: "reset-password-page",
    component: ResetPasswordPage,
    meta: { documentTitle: "Reset your password" },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.documentTitle) {
    document.title = `${to.meta.documentTitle} - eCase`;
  }

  actionStart(to.name);
  next();
});

export default router;