import { computed, ref } from "vue";

export function useNewPassword() {
  const password = ref("");
  const passwordConfirmation = ref("");

  const isMinimumEightCharacters = computed(() => password.value.length >= 8);
  const hasAtLeastOneLowerCaseCharacter = computed(() => /[a-z]/.test(password.value));
  const hasAtLeastOneUpperCaseCharacter = computed(() => /[A-Z]/.test(password.value));
  const hasAtLeastOneNumber = computed(() => /\d/.test(password.value));
  const hasAtLeastOneSpecialCharacter = computed(() => /[!@#$%^&*()\\_=+{};:,<.>-]/.test(password.value));
  const passwordsMatch = computed(() => password.value === passwordConfirmation.value);

  function getDisplayClasses(rule: boolean) {
    return ["ecase-sign-in__form-password-policy-list-item", rule ? "ecase-sign-in__form-password-policy-list-item--valid" : ""];
  }

  return {
    password,
    passwordConfirmation,

    isMinimumEightCharacters,
    hasAtLeastOneLowerCaseCharacter,
    hasAtLeastOneUpperCaseCharacter,
    hasAtLeastOneNumber,
    hasAtLeastOneSpecialCharacter,
    passwordsMatch,

    getDisplayClasses,
  };
}